import React, { useState, useEffect } from 'react';
import { useFetchData } from '../../hooks/codesAndVips';
import '../../MoreDataTable.css';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import ExcelJS from 'exceljs';
const getColorForShowPercentage = (percentage) => {
    if (percentage >= 70) return 'rgba(102, 204, 102, 0.6)'; // Pastel green
    if (percentage >= 50 && percentage < 70) return 'rgba(255, 255, 102, 0.6)'; // Pastel yellow
    return 'rgba(255, 102, 102, 0.6)'; // Pastel red
};

const getColorForGroupToHire = (percentage) => {
    if (percentage >= 50) return 'rgba(102, 204, 102, 0.6)'; // Pastel green
    if (percentage >= 35 && percentage < 50) return 'rgba(255, 255, 102, 0.6)'; // Pastel yellow
    return 'rgba(255, 102, 102, 0.6)'; // Pastel red
};

const getColorForSetToHire = (value) => {
    if (value <= 10) return 'rgba(102, 204, 102, 0.6)'; // Pastel green
    if (value >= 11 && value <= 15) return 'rgba(255, 255, 102, 0.6)'; // Pastel yellow
    return 'rgba(255, 102, 102, 0.6)'; // Pastel red
};


const MoreDataTable = ({ moreData }) => {
    const [tableTitle, setTableTitle] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [preparedData, setPreparedData] = useState([]);
    const [mgaSums, setMgaSums] = useState({});
    const [rgaSums, setRgaSums] = useState({});
    const [sortCategory, setSortCategory] = useState('MGA'); // New state for sorting

    const downloadExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('MORE Data');
    
        // Add "Simon Arias" in A1
        worksheet.mergeCells('A1');
        worksheet.getCell('A1').value = 'Simon Arias';
        worksheet.getCell('A1').font = { bold: true, size: 14 };
        worksheet.getCell('A1').alignment = { horizontal: 'left' };
    
        // Add "Date" in A2
        worksheet.mergeCells('A2');
        worksheet.getCell('A2').value = 'Date';
        worksheet.getCell('A2').font = { bold: true, size: 12 };
        worksheet.getCell('A2').alignment = { horizontal: 'left' };
    
        // Add the dynamic MORE date in A3
        worksheet.mergeCells('A3');
        worksheet.getCell('A3').value = tableTitle;
        worksheet.getCell('A3').font = { size: 12 };
        worksheet.getCell('A3').alignment = { horizontal: 'left' };
    
        // Merge and style External Recruiting section in B1:D2
        worksheet.mergeCells('B1:D2');
        worksheet.getCell('B1').value = 'External Recruiting';
        worksheet.getCell('B1').font = { bold: true, size: 12 };
        worksheet.getCell('B1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('B1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'e2efda' } };
    
        // Add the first row with merged headers (below the custom rows)
        worksheet.addRow([
            'Vendor Data', '', '',
            'Resume Data', '', '',
            'Personal Data', '', '',
            'Show & Invite Data', '', '', '', '',
            'Hiring Data', '', '', '', '', ''
        ]);
        worksheet.mergeCells('A4:C4');
        worksheet.mergeCells('D4:F4');
        worksheet.mergeCells('G4:I4');
        worksheet.mergeCells('J4:N4');
        worksheet.mergeCells('O4:T4');
    
        worksheet.getRow(4).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '474c55' } };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });
    
        // Add the second header row
        worksheet.addRow([
            'MGA Name', 'Sets', 'Shows', '%',
            'Sets', 'Shows', '%',
            'Sets', 'Shows', '%',
            'Total Set', 'Total Show', 'Group Invite', 'Percent Group',
            'Finals Set', 'Scheduled', 'Finals Show', 'Ratio',
            'Non-PR Hires', 'PR Hires', 'Total Hires', '% Final', '% Group Invite', 'Set to Hire'
        ]);
    
        worksheet.getRow(5).eachCell((cell) => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '319b43' } };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });
    
        // Add data rows
        preparedData.forEach((item) => {
            worksheet.addRow([
                item.mgaName,
                item.externalSets, item.externalShows, item.externalSets ? (item.externalShows / item.externalSets * 100).toFixed(2) : 0,
                item.internalSets, item.internalShows, item.internalSets ? (item.internalShows / item.internalSets * 100).toFixed(2) : 0,
                item.personalSets, item.personalShows, item.personalSets ? (item.personalShows / item.personalSets * 100).toFixed(2) : 0,
                item.totalSet, item.totalShow, item.groupInvite, item.totalShow ? (item.groupInvite / item.totalShow * 100).toFixed(2) : 0,
                item.finalsSet, item.scheduled, item.finalsShow, item.finalsSet ? (item.finalsShow / item.finalsSet * 100).toFixed(2) : 0,
                item.nonPRHires, item.PRHires, item.totalHires, item.finalsShow ? (item.totalHires / item.finalsShow * 100).toFixed(2) : 0,
                item.groupInvite ? (item.totalHires / item.groupInvite * 100).toFixed(2) : 0,
                item.setToHire
            ]);
        });
    
        // Apply conditional formatting to percentage columns
        const percentageColumns = [4, 7, 10, 14, 17, 21, 22];
        worksheet.eachRow((row, rowIndex) => {
            if (rowIndex > 5) { // Skip header rows
                percentageColumns.forEach((colIdx) => {
                    const cell = row.getCell(colIdx);
                    const value = parseFloat(cell.value) || 0;
    
                    let color;
                    if (value >= 70) color = '66CC66'; // Green
                    else if (value >= 50) color = 'FFFF66'; // Yellow
                    else color = 'FF6666'; // Red
    
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: color }
                    };
                });
            }
        });
    
        // Adjust column widths for better readability
        worksheet.columns.forEach((col) => {
            col.width = 15;
        });
    
        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${tableTitle} MORE.xlsx`;
        link.click();
    };
    

    useEffect(() => {
        if (moreData && moreData.length > 0) {
            const dates = moreData.map(item => new Date(item[0]));
    
            // Find the minimum and maximum dates
            const minDate = new Date(Math.min(...dates));
            const maxDate = new Date(Math.max(...dates));
    
            // Function to format a date into "m/d/yy"
            const formatDate = (date) => {
                const day = date.getDate();
                const month = date.getMonth() + 1; // Months are zero-indexed
                const year = date.getFullYear() % 100; // Get the last two digits of the year
                return `${month}/${day}/${year < 10 ? '0' + year : year}`;
            };
    
            // Set the title based on the number of unique dates
            const uniqueDates = new Set(dates.map(date => date.toDateString())).size;
            const title = uniqueDates > 1
                ? `${formatDate(minDate)}-${formatDate(maxDate)}`
                : formatDate(minDate);
    
            setTableTitle(title);
    
            // Prepare and sort the data
            let prepared = moreData.map(item => ({
                mgaName: formatMGAName(item.MGA), // MGA Name
                externalSets: parseInt(item.External_Sets, 10) || 0,
                externalShows: parseInt(item.External_Shows, 10) || 0,
                internalSets: parseInt(item.Internal_Sets, 10) || 0,
                internalShows: parseInt(item.Internal_Shows, 10) || 0,
                personalSets: parseInt(item.Personal_Sets, 10) || 0,
                personalShows: parseInt(item.Personal_Shows, 10) || 0,
                totalSet: parseInt(item.Total_Set, 10) || 0,
                totalShow: parseInt(item.Total_Show, 10) || 0,
                groupInvite: parseInt(item.Group_Invite, 10) || 0,
                percentGroup: parseFloat(item.Percent_Group) || 0.0,
                finalsSet: parseInt(item.Finals_Set, 10) || 0,
                scheduled: parseInt(item.Scheduled, 10) || 0,
                finalsShow: parseInt(item.Finals_Show, 10) || 0,
                ratio: parseFloat(item.Finals_Show_Ratio) || 0.0,
                nonPRHires: parseInt(item.Non_PR_Hires, 10) || 0,
                PRHires: parseInt(item.PR_Hires, 10) || 0,
                totalHires: parseInt(item.Total_Hires, 10) || 0,
                percentFinal: parseFloat(item.Percent_Final) || 0.0,
                percentGroupInvite: parseFloat(item.Percent_Group_Invite) || 0.0,
                setToHire: parseFloat(item.Set_To_Hire) || 0.0,
                RGA: item.RGA,
                Legacy: item.Legacy,
                Tree: item.Tree,
                Office: item.Office
            }));
    
            // Sort the entire prepared data alphabetically by default (MGA Name)
            prepared.sort((a, b) => a.mgaName.localeCompare(b.mgaName));
    
            // Group data by selected category and MGA name, then calculate sums
            const groupedData = {};
            prepared.forEach(item => {
                const key = `${item[sortCategory]}_${item.mgaName}`;
                if (!groupedData[key]) {
                    groupedData[key] = { ...item, key: item[sortCategory] }; // Initialize with the first item data
                } else {
                    // Aggregate data
                    groupedData[key].externalSets += item.externalSets;
                    groupedData[key].externalShows += item.externalShows;
                    groupedData[key].internalSets += item.internalSets;
                    groupedData[key].internalShows += item.internalShows;
                    groupedData[key].personalSets += item.personalSets;
                    groupedData[key].personalShows += item.personalShows;
                    groupedData[key].totalSet += item.totalSet;
                    groupedData[key].totalShow += item.totalShow;
                    groupedData[key].groupInvite += item.groupInvite;
                    groupedData[key].finalsSet += item.finalsSet;
                    groupedData[key].scheduled += item.scheduled;
                    groupedData[key].finalsShow += item.finalsShow;
                    groupedData[key].nonPRHires += item.nonPRHires;
                    groupedData[key].PRHires += item.PRHires;
                    groupedData[key].totalHires += item.totalHires;
                }
            });
    
            const groupedArray = Object.values(groupedData);
    
            // Sort grouped sections alphabetically
            groupedArray.sort((a, b) => a[sortCategory]?.localeCompare(b[sortCategory]));
    
            setPreparedData(groupedArray);
        }
    }, [moreData, sortCategory]);  // Dependency on moreData to refresh when it changes
    

    const totals = preparedData.reduce((acc, curr) => {
        // Loop over each key in the accumulator (initial totals)
        Object.keys(acc).forEach(key => {
            // Increase each total by the current item's corresponding value
            // Use '|| 0' to avoid NaN errors when data is missing
            acc[key] += curr[key] || 0;
        });
        return acc;
    }, {
        // Initialize totals for all data points you are summing
        externalSets: 0, externalShows: 0, internalSets: 0, internalShows: 0,
        personalSets: 0, personalShows: 0, totalSet: 0, totalShow: 0,
        groupInvite: 0, finalsSet: 0, scheduled: 0, finalsShow: 0,
        nonPRHires: 0, PRHires: 0, totalHires: 0
    });

    const formatMGAName = (name) => {
        if (!name) return '';
        const parts = name.split(' ');
        const lastName = parts[0]; // Assuming last name is the first part
        const firstName = parts[1] || ''; // First name is the second part (if exists)
        const middleInitial = parts[2] ? `${parts[2][0].toUpperCase()}.` : ''; // Optional middle initial

        const firstInitial = firstName ? `${firstName[0].toUpperCase()}.` : ''; // First initial
        return `${firstInitial} ${lastName}`;
    };

    return (
        <>
        <div className="more-header-with-toggle-and-filters">
        <h5>Table</h5>
            <div className="more-header-container">
                <div>
                    Sort by:
                    <select value={sortCategory} onChange={(e) => setSortCategory(e.target.value)}>
                        <option value="MGA">MGA</option>
                        <option value="RGA">RGA</option>
                        <option value="Tree">Tree</option>
                        <option value="Office">Office</option>
                    </select>
                </div>
                <button className='detailsToggleBtn' onClick={downloadExcel} title="Download as XLSX">
                    <FontAwesomeIcon icon={faDownload} />
                </button>
            </div>
        </div>
            <div className="more-data-container">


                <table className="morebonus-table">
                    <thead className="more-table-thead">
                        <tr>
                            <th>
                                <button
                                    onClick={() => setShowDetails(!showDetails)}
                                    className="detailsToggleBtn"
                                >
                                    {showDetails ? "Hide Details" : "Show Details"}
                                </button>
                            </th>
                            {showDetails && <>
                                <th style={{ backgroundColor: '#00548c' }} colSpan="3" className="more-table-header">Vendor Data</th>
                                <th style={{ backgroundColor: '#ED722F' }} colSpan="3" className="more-table-header">Resume Data</th>
                                <th style={{ backgroundColor: '#B25271' }} colSpan="3" className="more-table-header">Personal Data</th>

                            </>}

                            <th colSpan="9" className="more-table-header" >Show & Invite Data</th>

                            <th style={{ backgroundColor: '#474c55' }} colSpan="6" className="more-table-header" >Hiring Data</th>
                        </tr>
                        <tr>
                            <th style={{ backgroundColor: '#319b43bb', whiteSpace: 'nowrap' }} className="more-table-header" >MGA Name</th>
                            {showDetails && <>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Sets</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Shows</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">%</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Sets</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Shows</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">%</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Sets</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">Shows</th>
                                <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header">%</th>

                            </>}
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Set</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Show</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Show</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Group Invite</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Percent Group</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Finals Set</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Scheduled</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Finals Show</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Ratio</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Non-PR Hires</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >PR Hires</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Total Hires</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >% Final</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >% Group Invite</th>
                            <th style={{ backgroundColor: '#319b43bb' }} className="more-table-header" >Set to Hire</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(preparedData.reduce((acc, curr) => {
                            // Group by the selected category (e.g., MGA, RGA, etc.)
                            const key = curr[sortCategory];
                            if (!acc[key]) {
                                acc[key] = {};
                            }
                            const mgaKey = curr.mgaName;
                            if (!acc[key][mgaKey]) {
                                acc[key][mgaKey] = { ...curr, count: 1 }; // Initialize with the current item
                            } else {
                                // Aggregate data for items with the same MGA under the current category
                                acc[key][mgaKey].externalSets += curr.externalSets;
                                acc[key][mgaKey].externalShows += curr.externalShows;
                                acc[key][mgaKey].internalSets += curr.internalSets;
                                acc[key][mgaKey].internalShows += curr.internalShows;
                                acc[key][mgaKey].personalSets += curr.personalSets;
                                acc[key][mgaKey].personalShows += curr.personalShows;
                                acc[key][mgaKey].totalSet += curr.totalSet;
                                acc[key][mgaKey].totalShow += curr.totalShow;
                                acc[key][mgaKey].groupInvite += curr.groupInvite;
                                acc[key][mgaKey].finalsSet += curr.finalsSet;
                                acc[key][mgaKey].scheduled += curr.scheduled;
                                acc[key][mgaKey].finalsShow += curr.finalsShow;
                                acc[key][mgaKey].nonPRHires += curr.nonPRHires;
                                acc[key][mgaKey].PRHires += curr.PRHires;
                                acc[key][mgaKey].totalHires += curr.totalHires;
                                acc[key][mgaKey].count++;
                            }
                            return acc;
                        }, {})).map(([category, mgas]) => (
                            <React.Fragment key={category}>
                                {sortCategory !== 'MGA' && (
                                    <tr>
                                        <th colSpan="1" className="more-table-header" style={{ backgroundColor: '#ED722F' }}>{category}</th>
                                    </tr>
                                )}
                                {Object.entries(mgas).map(([mgaName, item], index) => (
                                    <tr key={index}>
                                        <th className="more-table-header more-table-first-col">{mgaName}</th>
                                        {showDetails && <>
                                            <td className="more-table-input-like">{item.externalSets}</td>
                                            <td className="more-table-input-like">{item.externalShows}</td>
                                            <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.externalShows / item.externalSets * 100) }}>{item.externalSets ? `${(item.externalShows / item.externalSets * 100).toFixed(2)}%` : '0%'}</td>
                                            <td className="more-table-input-like">{item.internalSets}</td>
                                            <td className="more-table-input-like">{item.internalShows}</td>
                                            <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.internalShows / item.internalSets * 100) }}>{item.internalSets ? `${(item.internalShows / item.internalSets * 100).toFixed(2)}%` : '0%'}</td>
                                            <td className="more-table-input-like">{item.personalSets}</td>
                                            <td className="more-table-input-like">{item.personalShows}</td>
                                            <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.personalShows / item.personalSets * 100) }}>{item.personalSets ? `${(item.personalShows / item.personalSets * 100).toFixed(2)}%` : '0%'}</td>
                                        </>}
                                        <td className="more-table-input-like">{item.totalSet}</td>
                                        <td className="more-table-input-like">{item.totalShow}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(item.totalShow / item.totalSet * 100) }}>{item.totalSet ? `${(item.totalShow / item.totalSet * 100).toFixed(2)}%` : '0%'}</td>
                                        <td className="more-table-input-like">{item.groupInvite}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.groupInvite / item.totalShow * 100)) }}>{item.totalShow ? `${(item.groupInvite / item.totalShow * 100).toFixed(2)}%` : '0%'}</td>
                                        <td className="more-table-input-like">{item.finalsSet}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.finalsSet / item.groupInvite * 100)) }}>{item.groupInvite ? `${(item.finalsSet / item.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
                                        <td className="more-table-input-like">{item.finalsShow}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.finalsShow / item.finalsSet * 100)) }}>{item.finalsSet ? `${(item.finalsShow / item.finalsSet * 100).toFixed(2)}%` : '0%'}</td>
                                        <td className="more-table-input-like">{item.nonPRHires}</td>
                                        <td className="more-table-input-like">{item.PRHires}</td>
                                        <td className="more-table-input-like">{item.totalHires}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.totalHires / item.finalsShow * 100)) }}>{item.finalsShow ? `${(item.totalHires / item.finalsShow * 100).toFixed(2)}%` : '0%'}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((item.totalHires / item.groupInvite * 100)) }}>{item.groupInvite ? `${(item.totalHires / item.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
                                        <td className="more-table-input-like" style={{ backgroundColor: getColorForSetToHire(parseInt(item.setToHire)) }}>{item.setToHire}</td>
                                    </tr>

                                ))}

                            </React.Fragment>
                        ))}
                        <tr className="totals-row">
                            <th className="more-table-header" style={{ backgroundColor: '#ED722F' }}>Totals</th>
                            {showDetails && <>
                                <td className="more-table-input-like">{totals.externalSets}</td>
                                <td className="more-table-input-like">{totals.externalShows}</td>
                                <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.externalShows / totals.externalSets * 100) }}>{(totals.externalShows && totals.externalSets) ? `${(totals.externalShows / totals.externalSets * 100).toFixed(2)}%` : '0%'}</td>
                                <td className="more-table-input-like">{totals.internalSets}</td>
                                <td className="more-table-input-like">{totals.internalShows}</td>
                                <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.internalShows / totals.internalSets * 100) }}>{(totals.internalShows && totals.internalSets) ? `${(totals.internalShows / totals.internalSets * 100).toFixed(2)}%` : '0%'}</td>
                                <td className="more-table-input-like">{totals.personalSets}</td>
                                <td className="more-table-input-like">{totals.personalShows}</td>
                                <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.personalShows / totals.personalSets * 100) }}>{(totals.personalShows && totals.personalSets) ? `${(totals.personalShows / totals.personalSets * 100).toFixed(2)}%` : '0%'}</td>
                            </>}
                            <td className="more-table-input-like">{totals.totalSet}</td>
                            <td className="more-table-input-like">{totals.totalShow}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForShowPercentage(totals.totalShow / totals.totalSet * 100) }}>{(totals.totalShow && totals.totalSet) ? `${(totals.totalShow / totals.totalSet * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{totals.groupInvite}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.groupInvite / totals.totalShow * 100)) }}>{(totals.groupInvite && totals.totalShow) ? `${(totals.groupInvite / totals.totalShow * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{totals.finalsSet}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.finalsSet / totals.groupInvite * 100)) }}>{(totals.finalsSet && totals.groupInvite) ? `${(totals.finalsSet / totals.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{totals.finalsShow}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire(totals.finalsShow / totals.finalsSet * 100) }}>{(totals.finalsShow && totals.finalsSet) ? `${(totals.finalsShow / totals.finalsSet * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like">{totals.nonPRHires}</td>
                            <td className="more-table-input-like">{totals.PRHires}</td>
                            <td className="more-table-input-like">{totals.totalHires}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.totalHires / totals.finalsShow * 100)) }}>{(totals.totalHires && totals.finalsShow) ? `${(totals.totalHires / totals.finalsShow * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForGroupToHire((totals.totalHires / totals.groupInvite * 100)) }}>{(totals.totalHires && totals.groupInvite) ? `${(totals.totalHires / totals.groupInvite * 100).toFixed(2)}%` : '0%'}</td>
                            <td className="more-table-input-like" style={{ backgroundColor: getColorForSetToHire(totals.setToHire) }}>{(totals.setToHire) ? totals.setToHire.toFixed(2) : '0'}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    );


};

export default MoreDataTable;
