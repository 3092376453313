import React from 'react';
import './PresentationDetails.css'; // Import the provided CSS

function PresentationDetails({ presentation, onBack }) {
    // Utility function to split a full name into first and last names
    const splitName = (fullName) => {
        if (!fullName) return { firstName: '', lastName: '' };
        const parts = fullName.trim().split(' ');
        return {
            firstName: parts[0] || '',
            lastName: parts.slice(1).join(' ') || '',
        };
    };

    // Splitting agent, primary, and spouse names
    const agentName = splitName(presentation.agent_name);
    const primaryName = splitName(presentation.primary_name);
    const spouseName = splitName(presentation.spouse_name);

    return (
        <>

        <form id="setupMyForm">
        <div className="setup-grid-container-start">
                <div className="setup-grid-item-left-start"></div>
                <div className="setup-grid-item-start">
                    <button
                        type="button"
                        className="setup-button"
                        onClick={onBack}
                    >
                        Back to History
                    </button>
                </div>
            </div>
            <div className="setup-grid-container">
            <div className="setup-grid-item-left">
            <label>Account Name:</label>
        </div>
        <div className="setup-grid-item">
            <span>{presentation.lagnname}</span>
        </div>
                <div className="setup-grid-item-left-agent">
                    <label>Agent Name:</label>
                </div>
                <div className="setup-grid-item-agent" style={{ textAlign: 'left' }}>
                    <input
                        type="text"
                        value={agentName.firstName}
                        className="setup-text-pi"
                        placeholder="First"
                        readOnly
                    />
                    <input
                        type="text"
                        value={agentName.lastName}
                        className="setup-text-pi"
                        placeholder="Last"
                        readOnly
                    />
                    <span>
                        Licensed for A&H{' '}
                        <input
                            type="checkbox"
                            checked={presentation.isLicensed || false}
                            className="setup-checkbox"
                            disabled
                        />
                    </span>
                </div>
                <div className="setup-grid-item-left">
                    <label>
                        <span style={{ color: 'red' }}>*</span> Lead Type:
                    </label>
                </div>
                <div className="setup-grid-item">
                    <select
                        value={presentation.lead_type || ''}
                        className="setup-drop"
                        disabled
                    >
                        <option value="">Choose Lead Type</option>
                        <option value="HARDCARD">HARD CARD</option>
                        <option value="WILLKIT">WILL KIT</option>
                        <option value="CHILDSAFE">CHILD SAFE</option>
                        <option value="REFERRAL">REFERRAL</option>
                    </select>
                </div>
                <div className="setup-grid-item-left">
                    <label>
                        <span style={{ color: 'red' }}>*</span> Primary:
                    </label>
                </div>
                <div className="setup-grid-item">
                    <input
                        type="text"
                        value={primaryName.firstName}
                        className="setup-text-pi"
                        placeholder="First"
                        readOnly
                    />
                    <input
                        type="text"
                        value={primaryName.lastName}
                        className="setup-text-pi"
                        placeholder="Last"
                        readOnly
                    />
                    <input
                        type="text"
                        value={presentation.primary_age || ''}
                        className="setup-text-pi"
                        placeholder="MMDDYYYY"
                        readOnly
                    />
                    <select
                        value={presentation.primary_sex || ''}
                        className="sex-drop"
                        disabled
                    >
                        <option value="male">M</option>
                        <option value="female">F</option>
                    </select>
                </div>
                <div className="setup-grid-item-left-spouse">
                    <label>Spouse:</label>
                </div>
                <div className="setup-grid-item-spouse">
                    <input
                        type="text"
                        value={spouseName.firstName}
                        className="setup-text-pi"
                        placeholder="First"
                        readOnly
                    />
                    <input
                        type="text"
                        value={spouseName.lastName}
                        className="setup-text-pi"
                        placeholder="Last"
                        readOnly
                    />
                    <input
                        type="text"
                        value={presentation.spouse_age || ''}
                        className="setup-text-pi"
                        placeholder="MMDDYYYY"
                        readOnly
                    />
                    <select
                        value={presentation.spouse_sex || ''}
                        className="sex-drop"
                        disabled
                    >
                        <option value="male">M</option>
                        <option value="female">F</option>
                    </select>
                </div>
                <div className="setup-grid-item-left">
                    <label>City:</label>
                </div>
                <div className="setup-grid-item">
                    <input
                        type="text"
                        value={presentation.city || ''}
                        className="setup-text"
                        placeholder="Member's City"
                        readOnly
                    />
                </div>
                <div className="setup-grid-item-left">
                    <label>
                        <span style={{ color: 'red' }}>*</span> State:
                    </label>
                </div>
                <div className="setup-grid-item">
                    <select
                        value={presentation.state || ''}
                        className="setup-drop"
                        disabled
                    >
                        <option value="WV">West Virginia</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="KY">Kentucky</option>
                        {/* Add more states if needed */}
                    </select>
                </div>
                <div className="setup-grid-item-left-pref">Extra Slides:</div>
                <div className="setup-grid-item-pref">
                <span>
        WL vs. Term{' '}
        <input
            type="checkbox"
            checked={presentation.wl_vs_term === 'WL'}
            className="setup-checkbox"
            disabled
        />
    </span>
                    <span>
                        FIG{' '}
                        <input
                            type="checkbox"
                            checked={presentation.extra_slide_fig || false}
                            className="setup-checkbox"
                            disabled
                        />
                    </span>
                </div>
                <div className="setup-grid-item-left">
            <label>Time Started:</label>
        </div>
        <div className="setup-grid-item">
            <span>{new Date(presentation.time_started).toLocaleString()}</span>
        </div>

        <div className="setup-grid-item-left">
            <label>Time Ended:</label>
        </div>
        <div className="setup-grid-item">
            <span>
                {presentation.time_ended
                    ? new Date(presentation.time_ended).toLocaleString()
                    : 'N/A'}
            </span>
        </div>

        <div className="setup-grid-item-left">
            <label>Total Time:</label>
        </div>
        <div className="setup-grid-item">
            <span>
                {presentation.time_ended
                    ? `${Math.floor(
                          (new Date(presentation.time_ended) -
                              new Date(presentation.time_started)) /
                              (1000 * 60)
                      )} minutes`
                    : 'In Progress'}
            </span>
        </div>
        <div className="setup-grid-item-left">
    <label>{presentation.time_ended ? 'Final Slide:' : 'Current Slide:'}</label>
</div>
<div className="setup-grid-item">
    <span>{presentation.current_slide || 'N/A'}</span>
</div>

            </div>
            

        </form>
        </>
    );
}

export default PresentationDetails;
