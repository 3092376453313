import React, { useState, useEffect, useRef } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // Ensure these imports are correct
import 'react-date-range/dist/theme/default.css';
import ActivityCards from './ActivityCard';
import StatisticsCards from './StatisticsCards';
import './Dashboard.css'; // Verify the path
import CombinedRank from '../Leaderboards/CombinedRank';
import FiltersContainer from '../utils/FiltersContainer';
import PnpDataComponent from '../Admin/PnP';
import AlpSpreadsheet from '../Report/AlpSpreadsheet';
import ReleaseBar from '../Release/ReleaseBar';
import GetUserInfo from '../utils/GetUserInfo';
import HistoryTable from '../Presentation/HistoryTable';
import Presentation from '../Presentation/Presentation';
import MainButtons from '../utils/MainButtons';
import Snowfall from 'react-snowfall';
import { useSnowfall } from '../../contexts/SnowfallContext';

const Dashboard = () => {
    const { isSnowfallEnabled } = useSnowfall();

    useEffect(() => {
        document.title = 'Dashboard';
    }, []);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const userRole = localStorage.getItem('userRole');
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [view, setView] = useState('myview');
    const userId = localStorage.getItem('userId');
    const [showUserInfo, setShowUserInfo] = useState(false); // State to control GetUserInfo visibility
    const [filters, setFilters] = useState({
        tree: [],
        legacy: [],
        rga: [],
        mga: [],
        agent: [],
    });

    const [filterOptions, setFilterOptions] = useState({
        trees: [],
        legacies: [],
        rgas: [],
        mgas: [],
        agents: [],
    });

    const [displayMode, setDisplayMode] = useState('both'); // 'both', 'calendar', 'presets'
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [isDateRangeHovered, setIsDateRangeHovered] = useState(false);
    const [releasedStatus, setReleasedStatus] = useState(null); // New state for released

    useEffect(() => {
        const checkUserInfo = async () => {
            try {
                const response = await fetch(
                    `https://ariaslogin-4a95935f6093.herokuapp.com/api/check-userinfo-exists?userId=${userId}`
                );
                const result = await response.json();
    
                if (result.success && !result.exists) {
                    setShowUserInfo(true); // Show GetUserInfo if the user doesn't exist
                } else {
                    setShowUserInfo(false); // Hide GetUserInfo if the user exists
                }
            } catch (error) {
                console.error('Error checking user information:', error);
            }
        };
    
        if (userId) {
            checkUserInfo();
        }
    }, [userId]);
    
    useEffect(() => {
        const checkReleasedStatus = async () => {
            try {
                const userId = localStorage.getItem('userId');
                const response = await fetch(`https://ariaslogin-4a95935f6093.herokuapp.com/api/check-released?userId=${userId}`);
                const result = await response.json();
                console.log(result);
                if (result.success) {
                    setReleasedStatus(result.isReleased);
                } else {
                    console.error("Failed to fetch release status");
                }
            } catch (error) {
                console.error("Error fetching release status:", error);
            }
        };
    
        checkReleasedStatus();
    }, []);
    
    const toggleDisplayMode = () => {
        setDisplayMode(displayMode === 'calendar' ? 'presets' : 'calendar');
    };


    const calculateDateRange = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
        const dayOfMonth = today.getDate();
        const currentDay = today.getDay();
        const daysToLastMonday = currentDay === 0 ? 6 : dayOfWeek - 1;

        // Calculate the most recent Monday
        const mostRecentMonday = new Date(today);
        mostRecentMonday.setDate(dayOfMonth - daysToLastMonday);
        mostRecentMonday.setHours(0, 0, 0, 0); // Start of the day

        // Calculate the following Sunday by adding 6 days to the most recent Monday
        const nextSunday = new Date(mostRecentMonday);
        nextSunday.setDate(mostRecentMonday.getDate() + 6);
        nextSunday.setHours(23, 59, 59, 999); // End of the day

        return [
            {
                startDate: mostRecentMonday,
                endDate: nextSunday,
                key: 'selection',
            },
        ];
    };

    const [dateRange, setDateRange] = useState(calculateDateRange());

    const sortOptionsAlphabetically = (options) => {
        return options.sort((a, b) => {
            // Ensure both a and b have labels
            if (!a.label || !b.label) return 0; // Consider how you want to handle undefined labels
            return a.label.localeCompare(b.label);
        });
    };

    const prevAgentsRef = useRef();

    useEffect(() => {
        if (isDataFetched) {
            const savedAgents = JSON.parse(localStorage.getItem('selectedAgents'));
            if (savedAgents && savedAgents.length > 0 && JSON.stringify(prevAgentsRef.current) !== JSON.stringify(filterOptions.agents.map(option => option.value))) {
                const validAgents = savedAgents.filter(agent => filterOptions.agents.some(option => option.value === agent));
                if (validAgents.length > 0) {
                    setFilters(prevFilters => ({ ...prevFilters, agent: validAgents }));
                }
                prevAgentsRef.current = filterOptions.agents.map(option => option.value);
            }
        }
    }, [isDataFetched, filterOptions.agents]);


    const initialSetupDone = useRef(false); // Ref to track if initial setup is done

    useEffect(() => {
        if (!initialSetupDone.current) { // Check if the initial setup hasn't been done yet
            const role = localStorage.getItem('userRole');
            const mgaRgaData = localStorage.getItem('mgaRgaData');
            if (mgaRgaData) {
                const { MGA } = JSON.parse(mgaRgaData);
                if (MGA) {
                    if (role === 'RGA') {
                        setFilters(prevFilters => ({
                            ...prevFilters,
                            rga: [MGA]
                        }));
                    } else if (role === 'MGA') {
                        setFilters(prevFilters => ({
                            ...prevFilters,
                            mga: [MGA]
                        }));
                    }
                }
            }
            initialSetupDone.current = true; // Mark the initial setup as done
        }
    }, []);

    useEffect(() => {
        applyHierarchicalFilters();
    }, [data, dateRange, filters]);
    // Helper function to parse dates as local time
    function parseDateAsLocal(input) {
        const [year, month, day] = input.split('-').map(val => parseInt(val, 10));
        // Create a date object in the local timezone
        return new Date(year, month - 1, day);
    }

    const applyHierarchicalFilters = () => {
        let currentFilteredData = data.filter(item => {
            const itemDate = parseDateAsLocal(item.date);
            const startDate = new Date(dateRange[0].startDate).setHours(0, 0, 0, 0);
            const endDate = new Date(dateRange[0].endDate).setHours(23, 59, 59, 999);
            return itemDate >= startDate && itemDate <= endDate;
        });

        if (filters.tree.length) {
            currentFilteredData = currentFilteredData.filter(item => filters.tree.includes(item.tree));
        }
        if (filters.legacy.length) {
            currentFilteredData = currentFilteredData.filter(item => filters.legacy.includes(item.legacy));
        }
        if (filters.rga.length) {
            currentFilteredData = currentFilteredData.filter(item =>
                filters.rga.includes(item.rga) || filters.rga.includes(item.mga)
            );
        }
        if (filters.mga.length) {
            currentFilteredData = currentFilteredData.filter(item => filters.mga.includes(item.mga));
        }
        if (filters.agent.length && !filters.agent.includes('all')) {
            currentFilteredData = currentFilteredData.filter(item => filters.agent.includes(item.agent));
        }

        setFilteredData(currentFilteredData);
        updateDropdownOptions(currentFilteredData); // Pass the filtered data for dropdown update
    };

    const datePickerRef = useRef(null);

    useEffect(() => {
        // Handler to call on outside click
        const handleClickOutside = event => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
                setIsPickerVisible(false); // Close the date picker
            }
        };
        // Add when mounted
        document.addEventListener("mousedown", handleClickOutside);

        // Return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const updateDropdownOptions = (dateFilteredData) => {
        const legaciesFilteredData = filters.tree.length ? dateFilteredData.filter(item => filters.tree.includes(item.tree)) : dateFilteredData;
        const rgasFilteredData = filters.legacy.length ? legaciesFilteredData.filter(item => filters.legacy.includes(item.legacy)) : legaciesFilteredData;
        const mgasFilteredData = filters.rga.length ? rgasFilteredData.filter(item => filters.rga.includes(item.rga)) : rgasFilteredData;
        const agentsFilteredData = filters.mga.length ? mgasFilteredData.filter(item => filters.mga.includes(item.mga)) : mgasFilteredData;

        setFilterOptions({
            trees: sortOptionsAlphabetically(getUniqueOptions(dateFilteredData, 'tree')),
            legacies: sortOptionsAlphabetically(getUniqueOptions(legaciesFilteredData, 'legacy')),
            rgas: sortOptionsAlphabetically(getUniqueOptions(rgasFilteredData, 'rga')),
            mgas: sortOptionsAlphabetically(getUniqueOptions(mgasFilteredData, 'mga')),
            agents: sortOptionsAlphabetically(getUniqueOptions(agentsFilteredData, 'agent')),
        });
    };

    const getUniqueOptions = (data, key) => {
        const unique = Array.from(new Set(data.map(item => item[key])))
            .map(value => ({ value, label: value }));
        return unique;
    };

    useEffect(() => {
        // Only add "Agency Wide Reported Activity" if it doesn't already exist
        if (!filterOptions.agents.find(option => option.value === 'all')) {
            setFilterOptions(prevOptions => ({
                ...prevOptions,
                agents: [{ value: 'all', label: 'Agency Wide Reported Activity' }, ...prevOptions.agents],
            }));
        }
    }, [filterOptions.agents, isDataFetched]); // Ensuring re-run only if necessary

    useEffect(() => {
        // Load saved agent selection from local storage
        const savedAgent = JSON.parse(localStorage.getItem('agentSelection'));
        if (savedAgent && savedAgent.length > 0) {
            // Update filters state to pre-select the saved agent
            setFilters(prevFilters => ({
                ...prevFilters,
                agent: savedAgent,
            }));
        }

        // Additional logic to load MGA/RGA for 'mga' role, if needed
        if (userRole === 'mga') {
            const savedMGA = JSON.parse(localStorage.getItem('mgaSelection'));
            const savedRGA = JSON.parse(localStorage.getItem('rgaSelection'));

            if (savedMGA && savedMGA.length > 0) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    mga: savedMGA,
                }));
            }

            if (savedRGA && savedRGA.length > 0) {
                setFilters(prevFilters => ({
                    ...prevFilters,
                    rga: savedRGA,
                }));
            }
        }
    }, [userRole]); // Runs on component mount and whenever userRole changes

    const handleSelect = (ranges) => {
        const { selection } = ranges;
        let startDate = new Date(selection.startDate);
        let endDate = new Date(selection.endDate);

        // Set start date to the beginning of the day
        startDate.setHours(0, 0, 0, 0);

        // If the same day is selected for start and end, adjust the end date to the end of the day
        if (startDate.toDateString() === endDate.toDateString()) {
            endDate.setHours(23, 59, 59, 999);
        } else {
            // If a range is selected, ensure the end date is also set to the end of the day
            endDate.setHours(23, 59, 59, 999);
        }

        setDateRange([{ startDate, endDate, key: 'selection' }]);
        applyHierarchicalFilters();
    };

    const togglePicker = () => {
        setIsPickerVisible(!isPickerVisible);
    };
    const [reportFormData, setReportFormData] = useState(() => {
        // Load saved data from local storage or initialize with default values
        const savedData = localStorage.getItem('reportFormData');
        return savedData ? JSON.parse(savedData) : {};
    });

    return (
        <div className="app-container">
            {isSnowfallEnabled && <Snowfall color="white" snowflakeCount={150} style={{ zIndex: 1 }} />}
            <div className="large-app-container">
                <MainButtons />
            {releasedStatus === false && <ReleaseBar />}
            {showUserInfo && <GetUserInfo />}
            <div className="header-with-toggle-and-filters">
                <div className="date-picker-and-filter-group">
                    <div className="date-picker-group"
                        style={{ position: 'relative' }}
                        onMouseEnter={() => setIsDateRangeHovered(true)}
                        onMouseLeave={() => setIsDateRangeHovered(false)}
                    >                    <button onClick={togglePicker} className="calendar-button">
                            <button className="toggle-display-mode-button" onClick={toggleDisplayMode}>
                                <i className="fas fa-sync"></i> |
                            </button>
                            <i className="fas fa-calendar-alt"></i>
                            <span>{dateRange[0].startDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</span>
                            <span> - </span>
                            <span>{dateRange[0].endDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</span>
                        </button>
                        {isPickerVisible && (
                            <div ref={datePickerRef} className={`date-picker-overlay ${displayMode === 'presets' ? 'show-presets' : ''}`}>
                                <DateRangePicker ranges={dateRange} onChange={handleSelect} direction='vertical' months={1} />
                            </div>
                        )}
                    </div>
                    {(view === 'none' || view === 'none') && (
                        <div className="filters-container">
                            <FiltersContainer filters={filters} setFilters={setFilters} filterOptions={filterOptions} />
                        </div>
                    )}
                </div>
                <div className="tabs">
                    <input
                        type="radio"
                        id="myview"
                        name="view_type"
                        value="myview"
                        checked={view === 'myview'}
                        onChange={() => setView('myview')}
                    />
                    <label htmlFor="myview">My View</label>
                    <input
                        type="radio"
                        id="team"
                        name="view_type"
                        value="team"
                        checked={view === 'team'}
                        onChange={() => setView('team')}
                    />
                    <label htmlFor="team">Team View</label>
                    <input
                        type="radio"
                        id="agency"
                        name="view_type"
                        value="agency"
                        checked={view === 'agency'}
                        onChange={() => setView('agency')}
                    />
                    <label htmlFor="agency">Agency View</label>
                </div>
            </div>


            <div className="content-container">
                <div className="cards-container">
                    <ActivityCards data={filteredData} startDate={dateRange[0]?.startDate} endDate={dateRange[0]?.endDate} view={view} isDateRangeHovered={isDateRangeHovered} />
                </div>
                <div className="leaderboards-container">
                    <CombinedRank view={view} data={filteredData} />
                </div>
            </div>
            </div>
        </div>
    );


}

export default Dashboard;
