import React, { createContext, useState, useContext } from 'react';

// Create the context
const SnowfallContext = createContext();

// Custom hook to use the context
export const useSnowfall = () => useContext(SnowfallContext);

// Provider component
export const SnowfallProvider = ({ children }) => {
    const [isSnowfallEnabled, setIsSnowfallEnabled] = useState(true);

    return (
        <SnowfallContext.Provider value={{ isSnowfallEnabled, setIsSnowfallEnabled }}>
            {children}
        </SnowfallContext.Provider>
    );
};
