import React, { useEffect, useState } from "react";
import "./MoreCards.css";

const MoreCards = ({ moreData }) => {
  const [processedData, setProcessedData] = useState({
    overviewsSet: 0,
    overviewsShow: 0,
    finalsSet: 0,
    finalsShow: 0,
    prHires: 0,
    totalHires: 0,
  });

  useEffect(() => {
    if (!moreData || !Array.isArray(moreData)) return;
  
    const processData = (data) => {
      const sums = data.reduce(
        (acc, item) => {
          acc.overviewsSet += parseFloat(item.Total_Set || 0);
          acc.overviewsShow += parseFloat(item.Total_Show || 0);
          acc.finalsSet += parseFloat(item.Finals_Set || 0);
          acc.finalsShow += parseFloat(item.Finals_Show || 0);
          acc.prHires += parseFloat(item.PR_Hires || 0);
          acc.totalHires += parseFloat(item.Total_Hires || 0);
          return acc;
        },
        {
          overviewsSet: 0,
          overviewsShow: 0,
          finalsSet: 0,
          finalsShow: 0,
          prHires: 0,
          totalHires: 0,
        }
      );
  
      // If finalsShow is less than totalHires, update finalsShow
      if (sums.finalsShow < sums.totalHires) {
        sums.finalsShow = sums.totalHires + sums.finalsShow;
      }
  
      setProcessedData(sums);
    };
  
    processData(moreData);
  }, [moreData]);
  

  // Calculate percentages
  const calculatePercentage = (part, total) => (total ? ((part / total) * 100).toFixed(0) : "0");

  // Fixed values for donut progress (SVG circle calculations)
  const circleRadius = 15.915; // Radius of the circle in the SVG
  const circumference = 2 * Math.PI * circleRadius;

  const calculateStrokeDasharray = (percentage) => {
    const filled = (percentage / 100) * circumference;
    return `${filled} ${circumference}`;
  };

  return (
    <div className="more-cards-container">
      {/* Overviews Card */}
      <div className="more-card">
        <div className="more-card-header">
          <h5>Overviews</h5>
          <div className="donut-container">
            <svg viewBox="0 0 36 36" className="donut-chart">
              <circle
                className="donut-ring"
                cx="18"
                cy="18"
                r={circleRadius}
                fill="transparent"
                stroke="#e0e0e0"
                strokeWidth="3"
              />
              <circle
                className="donut-segment"
                cx="18"
                cy="18"
                r={circleRadius}
                fill="transparent"
                stroke="#4caf50"
                strokeWidth="3"
                strokeDasharray={calculateStrokeDasharray(
                  calculatePercentage(processedData.overviewsShow, processedData.overviewsSet)
                )}
                strokeDashoffset="0"
              />
              <text x="18" y="20.35" textAnchor="middle" fontSize="7" fill="#333">
                {calculatePercentage(processedData.overviewsShow, processedData.overviewsSet)}%
              </text>
            </svg>
          </div>
        </div>
        <div className="more-card-body">
          <p className="more-value">
            <strong>
              {processedData.overviewsShow.toLocaleString()} /{" "}
              {processedData.overviewsSet.toLocaleString()}
            </strong>
          </p>
          <p className="more-metric">Show / Set</p>
        </div>
      </div>

      {/* Finals Card */}
      <div className="more-card">
        <div className="more-card-header">
          <h5>Finals</h5>
          <div className="donut-container">
            <svg viewBox="0 0 36 36" className="donut-chart">
              <circle
                className="donut-ring"
                cx="18"
                cy="18"
                r={circleRadius}
                fill="transparent"
                stroke="#e0e0e0"
                strokeWidth="3"
              />
              <circle
                className="donut-segment"
                cx="18"
                cy="18"
                r={circleRadius}
                fill="transparent"
                stroke="#3f51b5"
                strokeWidth="3"
                strokeDasharray={calculateStrokeDasharray(
                  calculatePercentage(processedData.finalsShow, processedData.finalsSet)
                )}
                strokeDashoffset="0"
              />
              <text x="18" y="20.35" textAnchor="middle" fontSize="7" fill="#333">
                {calculatePercentage(processedData.finalsShow, processedData.finalsSet)}%
              </text>
            </svg>
          </div>
        </div>
        <div className="more-card-body">
          <p className="more-value">
            <strong>
              {processedData.finalsShow.toLocaleString()} /{" "}
              {processedData.finalsSet.toLocaleString()}
            </strong>
          </p>
          <p className="more-metric">Show / Set</p>
        </div>
      </div>

      {/* Hires Card */}
      <div className="more-card">
        <div className="more-card-header">
          <h5>Hires</h5>
          <div className="donut-container">
            <svg viewBox="0 0 36 36" className="donut-chart">
              <circle
                className="donut-ring"
                cx="18"
                cy="18"
                r={circleRadius}
                fill="transparent"
                stroke="#e0e0e0"
                strokeWidth="3"
              />
              <circle
                className="donut-segment"
                cx="18"
                cy="18"
                r={circleRadius}
                fill="transparent"
                stroke="#ff9800"
                strokeWidth="3"
                strokeDasharray={calculateStrokeDasharray(
                    calculatePercentage(processedData.totalHires, processedData.finalsShow)
                )}
                strokeDashoffset="0"
              />
              <text x="18" y="20.35" textAnchor="middle" fontSize="7" fill="#333">
                {calculatePercentage(processedData.totalHires, processedData.finalsShow)
                }%
              </text>
            </svg>
          </div>
        </div>
        <div className="more-card-body">
          <p className="more-value">
          <strong>
        {processedData.totalHires.toLocaleString()} /{" "}
        {processedData.finalsShow.toLocaleString()}
      </strong>
          </p>
          <p className="more-metric">Hires / Finals</p>
        </div>
      </div>
    </div>
  );
};

export default MoreCards;
