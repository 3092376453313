import React, { useEffect, useState } from "react";
import "./MoreTopThreeLeaderboard.css";

const TopThreeLeaderboard = ({ data = [], allAmoreData = [] }) => {
  const [mgaData, setMgaData] = useState([]);
  const agnName = localStorage.getItem("agnName");

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const response = await fetch(
          "https://ariaslogin-4a95935f6093.herokuapp.com/api/get-mgas-with-activeusers"
        );
        const result = await response.json();

        if (result.success) {
          setMgaData(result.data);
        } else {
          console.error("Failed to fetch leaderboard data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchLeaderboardData();
  }, []);

  const validEntries = data.filter((entry) => entry.Total_Hires >= 0);

  // Sort data
  const sortedData = [...validEntries].sort((a, b) => {
    if (b.Total_Hires === a.Total_Hires) {
      return b.PR_Hires - a.PR_Hires; // Secondary sort by personal hires
    }
    return b.Total_Hires - a.Total_Hires;
  });

  const reformatName = (name) => {
    if (!name) return "Unknown";
    const capitalize = (word) =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    const parts = name.split(" ");
    const [last, first, ...rest] = parts;
    return `${capitalize(first || "")} ${capitalize(last || "")}`;
  };

  const assignRanks = (data) => {
    let rank = 1;
    let lastRank = rank;
    return data.map((entry, index) => {
      if (
        index > 0 &&
        data[index - 1].Total_Hires === entry.Total_Hires &&
        data[index - 1].PR_Hires === entry.PR_Hires
      ) {
        return { ...entry, rank: lastRank }; // Same rank for tie
      } else {
        lastRank = rank;
        rank++;
        return { ...entry, rank: lastRank };
      }
    });
  };
  

  const rankedData = assignRanks(sortedData);

  const renderRank = (rank) => {
    switch (rank) {
      case 1:
        return <span className="medal gold">🥇</span>; // Gold Medal
      case 2:
        return <span className="medal silver">🥈</span>; // Silver Medal
      case 3:
        return <span className="medal bronze">🥉</span>; // Bronze Medal
      default:
        return rank; // Default rank as number
    }
  };

  return (
    <div className="more-leaderboard-container">
      {/* Leaderboard Table */}
      <div className="more-table-container">
        {rankedData.length > 0 ? (
          <table className="more-leaderboard-table">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>MGA</th>
                <th>Total Hires</th>
                <th>PRs</th>
              </tr>
            </thead>
            <tbody>
              {rankedData.map((entry, index) => {
                const agentName = entry.MGA || "Name"; // Fallback to "Name" if no MGA provided

                // Find the corresponding MGA in mgaData
                const matchingMGA = mgaData.find((mga) => mga.lagnname === agentName);

                // Use profpic from activeuser if available, otherwise fallback
                const profilePic = matchingMGA?.activeuser?.profpic || `https://via.placeholder.com/40?text=${reformatName(agentName)[0] || "?"}`;

                return (
<tr
  key={index}
  className={entry.MGA === agnName ? "more-highlighted-row" : ""}
>
                    <td>{renderRank(entry.rank)}</td>
                    <td>
    {profilePic.includes("via.placeholder.com") ? (
      <div className="profile-placeholder">
        {reformatName(agentName).charAt(0)}
      </div>
    ) : (
      <img
        src={profilePic}
        alt={agentName}
        className="more-profile-pic"
      />
    )}
</td>

<td>    <span className="more-agent-name">{reformatName(agentName)}</span>
</td>
                    <td>{entry.Total_Hires}</td>
                    <td>{entry.PR_Hires}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="more-no-data-message">No data available.</p>
        )}
      </div>
    </div>
  );
};

export default TopThreeLeaderboard;
