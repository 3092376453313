import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EmailViewer from './EmailViewer';
// Checkmark Icon (Green Circle with Checkmark)
const CheckmarkIcon = () => (
  <svg
      height="20px"
      width="20px"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="-62.06 -62.06 434.40 434.40"
      xmlSpace="preserve"
      fill="#ffffff"
      stroke="#ffffff"
      strokeWidth="19.547451"
  >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="9.928864"></g>
      <g id="SVGRepo_iconCarrier">
          <g>
              <path
                  style={{ fill: "#00bd2f" }}
                  d="M155.139,0C69.598,0,0,69.598,0,155.139c0,85.547,69.598,155.139,155.139,155.139 c85.547,0,155.139-69.592,155.139-155.139C310.277,69.598,240.686,0,155.139,0z M144.177,196.567L90.571,142.96l8.437-8.437 l45.169,45.169l81.34-81.34l8.437,8.437L144.177,196.567z"
              ></path>
          </g>
      </g>
  </svg>
);


// Cross Icon (Red Circle with X)
const CrossIcon = () => (
    <svg
        viewBox="-3.84 -3.84 23.68 23.68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#ffffff"
        strokeWidth="0.736"
        height="20px"
        width="20px"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.29289 5.70711L6.58579 8L4.29289 10.2929L5.70711 11.7071L8 9.41421L10.2929 11.7071L11.7071 10.2929L9.41421 8L11.7071 5.70711L10.2929 4.29289L8 6.58579L5.70711 4.29289L4.29289 5.70711Z"
            fill="#e92b2b"
        />
    </svg>
);

function ApplicationDetails({ row, clientData, parseInsuredInfo, isQueuedTab, isDiscrepancyTab }) {
    const [selectedInsuredIndex, setSelectedInsuredIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedEmail, setEditedEmail] = useState(row.client_email);
    const [editedPhone, setEditedPhone] = useState(row.client_phoneNumber);
    const [emails, setEmails] = useState([]);
    const [filteredEmails, setFilteredEmails] = useState([]);
    const [viewingEmail, setViewingEmail] = useState(false);

    useEffect(() => {
        const fetchEmails = async () => {
            try {
                const response = await axios.get('https://ariaslogin-4a95935f6093.herokuapp.com/api/emails');
                setEmails(response.data);
            } catch (error) {
                console.error('Error fetching emails:', error);
            }
        };
    
        fetchEmails();
    
        if (clientData && row) {
            const discrepancies = getDiscrepancyData();
        } else {
        }
    }, [clientData, row]);
    
    
    useEffect(() => {
        if (emails.length > 0 && row.client_email) {
            const matchedEmails = emails.filter((email) => {
                const fromAddress = email?.from?.value?.[0]?.address; // Safely access the first email in the "from" array
                const toRecipients = email?.to?.value || []; // Default to an empty array if "to" is undefined
                
                return (
                    fromAddress === row.client_email || 
                    toRecipients.some((recipient) => recipient?.address === row.client_email)
                );
            });
            setFilteredEmails(matchedEmails);
        }
    }, [emails, row.client_email]);
    
    const renderMedicalQuestions = (insuredType, insuredName) => {
        const questions = insuredType === 'Senior'
            ? ['senior_rejected', 'heart_lung', 'cirrhosis', 'amputation', 'cancer_senior', 'oxygen']
            : ['anxiety_depression', 'cancer', 'chronic_illness', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'high_blood_pressure', 'medications'];
    
        return (
            <table className='recruiting-table'>
                <thead>
                    <tr>
                        <th>Question</th>
                        <th>Agent Answer</th>
                        {!isQueuedTab && <th>Client Answer</th>}
                    </tr>
                </thead>
                <tbody>
                    {questions.map((questionKey) => {
                        // Process agent answer with multiple insureds
                        const agentAnswerRaw = row[`${questionKey}_answer`] || 'n';
                        let agentAnswer;
    
                        if (agentAnswerRaw.toLowerCase().includes('yes(')) {
                            const insuredsWithYes = agentAnswerRaw.match(/\(([^)]+)\)/)[1]
                                .split(',')
                                .map(name => name.trim().toLowerCase());
                            
                            if (insuredsWithYes.includes(insuredName.toLowerCase())) {
                                agentAnswer = 'Yes';
                            } else {
                                agentAnswer = 'No'; // Show "No" if this insured isn't listed as "Yes"
                            }
                        } else {
                            agentAnswer = agentAnswerRaw === 'n' ? 'No' : 'Yes';
                        }
    
                        // Process client answer similarly
                        const clientAnswerRaw = clientData?.[questionKey] || 'undefined';
                        let clientAnswer;
    
                        if (clientAnswerRaw.toLowerCase().includes(`yes(`)) {
                            const insuredsWithYes = clientAnswerRaw.match(/\(([^)]+)\)/)[1]
                                .split(',')
                                .map(name => name.trim().toLowerCase());
                            
                            if (insuredsWithYes.includes(insuredName.toLowerCase())) {
                                clientAnswer = 'Yes';
                            } else {
                                clientAnswer = 'No'; // Show "No" if this insured isn't listed as "Yes"
                            }
                        } else if (clientAnswerRaw === 'n') {
                            clientAnswer = 'No';
                        } else {
                            clientAnswer = 'N/A';
                        }
    
                        const isDiscrepancy = agentAnswer === 'No' && clientAnswer === 'Yes';
    
                        return (
                            <tr key={questionKey} style={{ backgroundColor: isDiscrepancy ? 'lightcoral' : 'white' }}>
                                <td>{capitalizeWords(questionKey)}</td>
                                <td>{agentAnswer}</td>
                                {!isQueuedTab && <td>{clientAnswer}</td>}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };
    
    
    
    const capitalizeWords = (str) => {
        return str
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
    };

    
    const handleEditClick = () => {
        if (row.status !== 'Received') { // Only allow editing if the status is not 'Received'
            setIsEditing(true);
        }
    };
    const convertMSTToUTC = (mstDateString) => {
        const mstDate = new Date(mstDateString);
        const utcDate = new Date(mstDate.getTime() + 7 * 60 * 60 * 1000); // Add 7 hours to get UTC
        return utcDate.toISOString(); // Convert to ISO string in UTC format
    };
    
    const convertUTCToLocalTime = (utcDateString) => {
        const utcDate = new Date(utcDateString);
        return utcDate.toLocaleString(); // Convert to local time based on user's time zone
    };
    
    
  const handleSaveClick = async () => {
    try {
        // Send a PUT request to the backend with both phone and email
        const response = await axios.put('https://ariaslogin-4a95935f6093.herokuapp.com/api/verify/update-client-info', {
            application_id: row.application_id, // Use the application's ID to update the record
            client_email: editedEmail, // Send the updated or original email
            client_phoneNumber: editedPhone, // Send the updated or original phone number
        });

        // Handle successful response
        if (response.data.success) {
            alert('Client information updated successfully.');

            // Update the row state directly with the new values
            row.client_email = editedEmail;
            row.client_phoneNumber = editedPhone;
        }

        setIsEditing(false); // Exit editing mode after saving
    } catch (error) {
        console.error('Error updating client information:', error);
        alert('Failed to update client information.');
    }
};

const [selectedVerifiedRow, setSelectedVerifiedRow] = useState(null);
const handleResend = async (application_id) => {
    try {
        // Send POST request to the backend to trigger the resend
        const response = await axios.post('https://ariaslogin-4a95935f6093.herokuapp.com/api/resendverify', {
            application_id // Send the application ID in the body of the request
        });

        // Handle successful response
        if (response.data.success) {
            alert(`Application ${application_id} resent successfully.`);
        } else {
            alert(`Failed to resend application: ${response.data.message}`);
        }
    } catch (error) {
        console.error(`Error resending application ${application_id}:`, error);
        alert('Error resending the application.');
    }
};

const medicalDiscrepancyMessages = {
    medications: "Needs medications listed on medical info sheet",
    er_visit: "Needs details of overnight hospital stay on medical info sheet",
    high_blood_pressure: "Needs High Blood Pressure Questionnaire",
    diabetes: "Needs Diabetes Questionnaire",
    cancer: "Needs Cancer Questionnaire",
    arrested: "Needs Arrest Questionnaire",
    dui: "Needs Alcohol Use, Drug, and Arrest Questionnaires",
    anxiety_depression: "Needs Depression Questionnaire",
    heart_issues: "Needs Heart/Circulatory Questionnaire",
    senior_rejected: "Was rejected for life with AIL",
    heart_lung: "Heart/Lung question discrepancy",
    cirrhosis: "Cirrhosis, Alzheimer's, ALS, dementia discrepancy",
    amputation: "Amputation question discrepancy",
    cancer_senior: "Cancer question discrepancy",
    oxygen: "Oxygen question discrepancy",
};

const checkForMismatch = (row, insuredType, clientData, insuredName) => {
    const medicalQuestions = insuredType === 'Senior'
        ? ['senior_rejected', 'heart_lung', 'cirrhosis', 'amputation', 'cancer_senior', 'oxygen']
        : ['anxiety_depression', 'cancer', 'chronic_illness', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'high_blood_pressure', 'medications'];

    return medicalQuestions.some((key) => {
        const agentAnswer = row[`${key}_answer`] || 'n';
        const clientAnswer = clientData?.[key] || 'n';

        // Extract insured names from answers for comparison
        const clientInsureds = clientAnswer.toLowerCase().includes('yes(')
            ? clientAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
            : [];

        const agentInsureds = agentAnswer.toLowerCase().includes('yes(')
            ? agentAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
            : [];

        // Discrepancy exists if the agent said "No" and the client includes this insured
        return !agentInsureds.includes(insuredName.toLowerCase()) && clientInsureds.includes(insuredName.toLowerCase());
    });
};


const getDiscrepancyData = () => {
    const discrepancies = [];
    const insuredDiscrepancies = {};

    if (!clientData || !row) {
        return discrepancies;
    }

    const medicalKeys = [
        'amputation', 'anxiety_depression', 'cancer', 'cancer_senior', 'chronic_illness',
        'cirrhosis', 'diabetes', 'dui', 'er_visit', 'heart_issues', 'heart_lung',
        'high_blood_pressure', 'medications', 'oxygen', 'senior_rejected'
    ];

    const insureds = {
        Primary: row.primary_info,
        Spouse: row.spouse_info,
        Child1: row.child1_info,
        Child2: row.child2_info,
        Child3: row.child3_info,
        Child4: row.child4_info,
        Child5: row.child5_info,
        Child6: row.child6_info,
        Child7: row.child7_info,
        Child8: row.child8_info,
        Child9: row.child9_info,
    };

    for (const [insuredType, insuredInfo] of Object.entries(insureds)) {
        if (insuredInfo && insuredInfo !== 'n/a') {
            const [insuredName] = insuredInfo.split(',');

            const insuredDiscrepanciesList = [];
            medicalKeys.forEach((key) => {
                const agentAnswer = row[`${key}_answer`] || 'n';
                const clientAnswer = clientData[key] || 'n';

                // Extract insured names from answers for comparison
                const agentInsureds = agentAnswer.toLowerCase().includes('yes(')
                    ? agentAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
                    : [];

                const clientInsureds = clientAnswer.toLowerCase().includes('yes(')
                    ? clientAnswer.match(/\(([^)]+)\)/)[1].split(',').map(name => name.trim().toLowerCase())
                    : [];

                // Check for discrepancies: Agent says "No" (n) and Client lists this insured
                const isDiscrepancy = !agentInsureds.includes(insuredName.toLowerCase()) &&
                    clientInsureds.includes(insuredName.toLowerCase());

                if (isDiscrepancy) {
                    insuredDiscrepanciesList.push(
                        `- ${capitalizeWords(key)} discrepancy: Agent said No, Client said Yes for ${insuredName}. ${medicalDiscrepancyMessages[key]}`
                    );
                }
            });

            if (insuredDiscrepanciesList.length > 0) {
                insuredDiscrepancies[insuredName] = insuredDiscrepanciesList;
            }
        }
    }

    const formattedDiscrepancies = [];
    for (const [insured, messages] of Object.entries(insuredDiscrepancies)) {
        formattedDiscrepancies.push(`Insured: ${insured}\n${messages.join('\n')}`);
    }

    return formattedDiscrepancies;
};


const discrepancyData = getDiscrepancyData();
// Button click handler to copy discrepancies
const handleCopyClick = () => {
    const discrepancyText = getDiscrepancyData().join('\n');
    navigator.clipboard.writeText(`Discrepancies for ${row.client_name}:\n${discrepancyText}`);
    alert(`Copied: Discrepancies for ${row.client_name}`);
};

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    return date.toLocaleString('en-US', options);
};
  const handleCancelClick = () => {
      // Reset edits and close the editing mode
      setEditedEmail(row.client_email);
      setEditedPhone(row.client_phoneNumber);
      setIsEditing(false);
  };
  const formatPhoneNumber = (value) => {
    // Remove all non-digit characters
    const cleaned = ('' + value).replace(/\D/g, '');

    // Apply formatting as the user types
    if (cleaned.length <= 3) {
        return cleaned;
    }
    if (cleaned.length <= 6) {
        return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    }
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
};
    const handleInsuredRowClick = (index) => {
        setSelectedInsuredIndex(selectedInsuredIndex === index ? null : index);
    };

    const renderInsuredsTable = () => {
        const insureds = [];
        const primaryInfo = parseInsuredInfo(row.primary_info);
        if (primaryInfo) insureds.push(primaryInfo);

        const spouseInfo = parseInsuredInfo(row.spouse_info);
        if (spouseInfo) insureds.push(spouseInfo);

        for (let i = 1; i <= 9; i++) {
            const childInfo = parseInsuredInfo(row[`child${i}_info`]);
            if (childInfo) insureds.push(childInfo);
        }

        return (
            <table className='recruiting-table'>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>MBD</th>
                        <th>Status</th>
                        <th>App Type</th>
                    </tr>
                </thead>
                <tbody>
                    {insureds.length > 0 ? (
                        insureds.map((insured, index) => {
                            const hasMismatch = clientData && checkForMismatch(row, insured.type, clientData, insured.name);
                            return (
                                <React.Fragment key={index}>
                                    <tr onClick={() => handleInsuredRowClick(index)} style={{ cursor: 'pointer' }}>
                                        <td>{clientData && (hasMismatch ? <CrossIcon /> : <CheckmarkIcon />)}</td>
                                        <td>{insured.name}</td>
                                        <td>{Number(insured.premium).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                                        <td>{insured.trial}</td>
                                        <td>{insured.type}</td>
                                    </tr>
                                    {selectedInsuredIndex === index && (
                                        <tr>
                                            <td colSpan="5">{renderMedicalQuestions(insured.type, insured.name)}</td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            );
                        })
                    ) : (
                        <tr><td colSpan="5">No insureds found.</td></tr>
                    )}
                </tbody>
            </table>
        );
    };

    
    
    const getSetForSendDate = (submittedDate) => {
        const submitted = new Date(submittedDate);
        const nextDay = new Date(submitted);
        nextDay.setDate(submitted.getDate() + 1); // Move to the next day
        nextDay.setHours(8, 0, 0, 0); // Set time to 8:00 AM
        return nextDay.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    };


    // Conditional row styling if IP addresses match
    const rowStyle = row.agent_ip === clientData?.client_ip ? { backgroundColor: 'lightcoral' } : {};

    return (
        <div>
            {/* Agent, Client, IP Comparison Table */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <h4>Details</h4>
    {isDiscrepancyTab && (
                    <div style={{ textAlign: 'right' }}>
                        <button className='insured-button' onClick={handleCopyClick}>Copy Notes</button>
                    </div>
                )}
    {isEditing && (
        <div style={{ textAlign: 'right' }}>
            <button className='insured-button' onClick={handleSaveClick}>Save</button>
            <button className='insured-button' onClick={handleCancelClick}>Cancel</button>
        </div>
    )}
</div>


            <table className='hierarchyTable'>
    <thead>
        <tr>
            <th>Agent</th>
            <th>Client Email</th>
            <th>Client Phone</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{row.agent_name}</td>
            <td
    style={{
        border: isEditing ? '2px solid green' : '2px solid #d1d5db',
    }}
>
    {isEditing ? (
        <input
            type="email"
            value={editedEmail}
            onChange={(e) => setEditedEmail(e.target.value)}
            style={{
                width: '100%',
                backgroundColor: '#f8f9fa',
            }}
        />
    ) : (
        <span onClick={handleEditClick} style={{ cursor: row.status !== 'Received' ? 'pointer' : 'default' }}>
            {row.client_email}
        </span>
    )}
</td>

<td
    style={{
        border: isEditing ? '2px solid green' : '2px solid #d1d5db',
    }}
>
    {isEditing ? (
        <input
            type="text"
            value={editedPhone}
            onChange={(e) => setEditedPhone(formatPhoneNumber(e.target.value))}
            style={{
                width: '100%',
                backgroundColor: '#f8f9fa',
            }}
            maxLength="12" // Limits the input to 12 characters (xxx-xxx-xxxx)
        />
    ) : (
        <span onClick={handleEditClick} style={{ cursor: row.status !== 'Received' ? 'pointer' : 'default' }}>
            {row.client_phoneNumber}
        </span>
    )}
</td>


        </tr>
    </tbody>
</table>


{clientData && (
    <>
        <table className='recruiting-table'>
            <thead>
                <tr>
                    <th>Agent IP</th>
                    <th>Client IP</th>
                </tr>
            </thead>
            <tbody>
                <tr style={rowStyle}>
                    <td>{row.agent_ip}</td>
                    <td>{clientData?.client_ip || 'No client IP'}</td>
                </tr>
            </tbody>
        </table>

        <table className='recruiting-table'>
            <thead>
                <tr>
                    <th>Checking</th>
                    <th>Coverage Consent</th>
                    <th>Contact Agent</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    {/* Checking - transform y/n to Yes/No and apply background */}
                    <td style={{
                        backgroundColor: clientData?.account_verification === 'n' ? 'lightcoral' : 'inherit'
                    }}>
                        {clientData?.account_verification === 'y' ? 'Yes' : 'No'}
                    </td>

                    {/* Coverage Consent - transform y/n to Yes/No and apply background */}
                    <td style={{
                        backgroundColor: clientData?.application_verification === 'n' ? 'lightcoral' : 'inherit'
                    }}>
                        {clientData?.application_verification === 'y' ? 'Yes' : 'No'}
                    </td>

                    {/* Contact Agent - display actual data from the backend and apply background if not 'No' */}
                    <td style={{
                        backgroundColor: clientData?.agent_contact_request !== 'No' ? 'lightcoral' : 'inherit'
                    }}>
                        {clientData?.agent_contact_request || 'N/A'} {/* Display actual value from backend */}
                    </td>
                </tr>
            </tbody>
        </table>
    </>
)}



            <h4>Insureds</h4>
            {renderInsuredsTable()}
    {/* Conditionally show the Resend button if the status is NOT 'received' 
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
    <h4>Communications</h4>
    {isQueuedTab ? (
    row.status !== 'Received' && (
        <button className='insured-button' onClick={() => handleResend(row.application_id)}>
            Send Early
        </button>
    )
) : (
    row.status !== 'Received' && (
        <button className='insured-button' onClick={() => handleResend(row.application_id)}>
            Resend
        </button>
    )
)}
</div>

<div className='recruiting-table-container'>
<table className='recruiting-table'>
    <thead>
        <tr>
            <th>Status</th>
            <th>Send Count</th>
            <th>Last Resent</th>
            <th>Submitted</th>
            {isQueuedTab && <th>Queued For</th>} 
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>{row.status}</td>
            <td>{row.resend_count}</td>
            <td>
                {row.resent_time ? convertUTCToLocalTime(convertMSTToUTC(row.resent_time)) : 'N/A'}
            </td>
            <td>
                {convertUTCToLocalTime(convertMSTToUTC(row.created_at))}
            </td>
            {isQueuedTab && (
                <td>{getSetForSendDate(row.created_at)}</td> 
            )}
        </tr>
    </tbody>
</table>
</div>

<EmailViewer row={row} />
*/}
        </div>
    );
}

export default ApplicationDetails;
