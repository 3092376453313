import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './MainButtons.css'; // Optional for custom styles if needed.

const MainButtons = () => {
    const popupWindows = React.useRef([]);
    const handleLaunchPresentation = () => {
        const userId = localStorage.getItem('userId');
        const userToken = localStorage.getItem('userToken');
        const presUrl = `https://ariaslife.com/temp/agent_tools/presentation/pres_setup.html?a=${userToken}&b=${userId}`;
        openPres(presUrl);
    };

    const openPres = (presFile) => {
        const screenWidth = window.screen.availWidth;
        const screenHeight = window.screen.availHeight;
        const width = screenWidth > 3800 ? Math.round(screenWidth * 0.35) : Math.round(screenWidth * 0.7);
        const height = Math.round(screenHeight * 0.7);
        const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2.61) : 0;
        const top = screenHeight - height;

        let openedWindow = null;
        for (const win of popupWindows.current) {
            if (!win.closed) {
                openedWindow = win;
                break;
            }
        }

        if (openedWindow) {
            openedWindow.location.href = presFile;
            openedWindow.focus();
            openedWindow.moveToTop();
        } else {
            const presWindow = window.open(
                presFile,
                '_blank',
                `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
            );
            popupWindows.current.push(presWindow);
            presWindow.focus();
        }
    };

  const openPhone = (phoneFile) => {
    const screenWidth = window.screen.availWidth;
    const screenHeight = window.screen.availHeight;
    const width = screenWidth > 3800 ? Math.round(screenWidth * 0.5) : window.screen.availWidth;
    const height = window.screen.availHeight;
    const left = screenWidth > 3800 ? Math.round((screenWidth - width) / 2) : 0;
    const top = 0;

    const phoneWindow = window.open(
      phoneFile,
      '_blank',
      `toolbar=no,scrollbars=no,resizable=yes,top=${top},left=${left},width=${width},height=${height},alwaysRaised=true`
    );
    phoneWindow.focus();
  };

  return (
    <div className="main-buttons-row">
      
<button
  className="main-custom-button main-gray-button"
  onClick={() => window.open('https://ariaslife.mykajabi.com/', '_blank', 'noopener noreferrer')}
>
  New Agent Training Course
</button>

      <button
        onClick={() => openPhone('https://ariaslife.com/temp/agent_tools/phone_scripts/phone_scripts.html')}
        className="main-custom-button main-orange-button"
      >
        Phone Scripts
      </button>
      <button
        onClick={() => handleLaunchPresentation()}
        className="main-custom-button main-green-button"
      >
        Launch Presentation
      </button>
    </div>
  );
};

export default MainButtons;
