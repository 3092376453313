import React from "react";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Area } from "@visx/shape";
import { curveBasis } from "@visx/curve";
import { LinearGradient } from "@visx/gradient";

// Helper functions
const x = (d) => d.index;
const y = (d) => Math.max(d.value, 0.25);

function interpolatePoints(current, next) {
  if (!next) return current;
  const xStep = 0.25;
  const yStep = Math.abs(y(next) - y(current)) * 0.03;
  const yMid1 = Math.abs(y(current) - yStep);
  const yMid2 = Math.abs(y(next) + yStep);
  const xMid1 = Math.abs(x(current) + xStep);
  const xMid2 = Math.abs(x(next) - xStep);
  return [
    current,
    { index: xMid1, value: yMid1 },
    { index: xMid2, value: yMid2 }
  ];
}

function interpolateData(data) {
  return data.map((d, i) => interpolatePoints(d, data[i + 1])).flat();
}

const FunnelGraph = ({ segments = [], aspectRatio = 0.5 }) => {
  if (!segments.length) return null; // Handle empty data gracefully

  const data = interpolateData(segments);

  return (
    <ParentSize>
      {({ width }) => {
        const adjustedHeight = width /5;
        const numSegments = segments.length > 0 ? Math.max(...segments.map((seg) => seg.index)) : 0;
        const maxValue = Math.max(...data.map((d) => d.value), 0);
        const labelFontSize = Math.max(12, Math.min(16, width / 50)); // Scale between 12 and 16
        const valueFontSize = Math.max(16, Math.min(22, width / 40)); // Scale between 16 and 22
        const sectionWidth = numSegments > 0 ? width / numSegments : 0;
        const labelOffset = sectionWidth / 2; // Center the label in the section
                
        const valuePadding = 20;
        const minmax = maxValue + valuePadding;
        const padding = numSegments > 0 ? width / numSegments / 2 : 0;

        const xScale = scaleLinear({
          range: [0, width],
          domain: [0, numSegments],
        });
        const yScale = scaleLinear({
          range: [adjustedHeight, 0],
          domain: [-minmax, minmax],
        });

        const areas = [
          { pad: 5, opacity: 1 },
          { pad: 15, opacity: 0.2 },
          { pad: 25, opacity: 0.1 },
        ];


        return (
          <svg width={width} height={adjustedHeight}>
            <LinearGradient id="gradient" from="#2167f9" to="#a479ff" vertical={false} />
            <rect width={width} height={adjustedHeight} fill="#14134b" rx={22} />
            
            {/* Draw gradient areas */}
            {areas.map((area, i) => (
              <Area
                key={`area-${i}`}
                data={data}
                curve={curveBasis}
                x={(d) => xScale(d.index)}
                y0={(d) => yScale(d.value + area.pad)}
                y1={(d) => yScale(-d.value - area.pad)}
                fill="url(#gradient)"
                fillOpacity={area.opacity}
                stroke="transparent"
              />
            ))}

{/* Draw labels and vertical lines */}
{segments.map((segment, i) => (
  <React.Fragment key={`label-${i}`}>
    {/* Label for the segment */}
    <text
      x={xScale(segment.index) - labelOffset} // Center the label horizontally
      y={20} // Position at the top of the graph
      dy=".33em"
      fill="white"
      fontSize={labelFontSize} // Use dynamic font size
      textAnchor="middle" // Center align text
    >
      {segment.stage} {/* Use stage name as the label */}
    </text>
    
    {/* Vertical line */}
    <line
      x1={xScale(segment.index)}
      x2={xScale(segment.index)}
      y1={0}
      y2={adjustedHeight}
      stroke="white"
      strokeWidth={4}
    />
  </React.Fragment>
))}
            
{/* Draw the values */}
{segments.map((segment) => (
  <text
    key={`value-${segment.index}`}
    x={xScale(segment.index) + padding}
    y={adjustedHeight / 2}
    dy={".33em"}
    fill="white"
    fontSize={valueFontSize} // Use dynamic font size
    textAnchor="middle"
  >
    {`${segment.value.toFixed(0)}`} {/* Show rounded values */}
  </text>
))}

          </svg>
        );
      }}
    </ParentSize>
  );
};

export default FunnelGraph;
