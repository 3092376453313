import React, { useState } from 'react';
import './GetUserInfo.css'; // Assuming the styles are saved here
const CheckmarkIcon = () => (
  <svg
    height="24px"
    width="24px"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="-62.06 -62.06 434.40 434.40"
    xmlSpace="preserve"
    fill="#ffffff"
    stroke="#ffffff"
    strokeWidth="19.547451"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="#CCCCCC"
      strokeWidth="9.928864"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          style={{ fill: '#00bd2f' }}
          d="M155.139,0C69.598,0,0,69.598,0,155.139c0,85.547,69.598,155.139,155.139,155.139 c85.547,0,155.139-69.592,155.139-155.139C310.277,69.598,240.686,0,155.139,0z M144.177,196.567L90.571,142.96l8.437-8.437 l45.169,45.169l81.34-81.34l8.437,8.437L144.177,196.567z"
        ></path>
      </g>
    </g>
  </svg>
);

const GetUserInfo = ({ onClose }) => {
  const [phone, setPhone] = useState(['', '', '']);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isEmailVerifying, setIsEmailVerifying] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);

  const handlePhoneChange = (index, value) => {
    const maxLength = index === 2 ? 4 : 3; // Adjust for 3-3-4 format
    if (/^\d*$/.test(value) && value.length <= maxLength) {
      const newPhone = [...phone];
      newPhone[index] = value;
      setPhone(newPhone);

      // Move to next input if filled
      if (value.length === maxLength && index < phone.length - 1) {
        document.getElementById(`phone-${index + 1}`).focus();
      }
    }
  };

  const handlePhoneBackspace = (index, value) => {
    if (value === '' && index > 0) {
      document.getElementById(`phone-${index - 1}`).focus();
    }
  };

  const handlePhoneBlur = async () => {
    const fullPhone = phone.join(''); // Combine all phone segments into a single string
    if (fullPhone.length !== 10) {
      setPhoneError('Phone number must be 10 digits.');
      setIsPhoneVerified(false);
      return;
    }

    try {
      const response = await fetch(
        'https://ariaslogin-4a95935f6093.herokuapp.com/api/verifyPhone',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone: fullPhone }),
        }
      );

      const result = await response.json();

      if (result.success) {
        setPhoneError('');
        setIsPhoneVerified(true); // Phone number is valid
      } else {
        setPhoneError(result.message || 'Invalid phone number.');
        setIsPhoneVerified(false);
      }
    } catch (error) {
      setPhoneError('Error verifying phone number. Please try again.');
      setIsPhoneVerified(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
    setIsEmailVerified(false); // Reset verification when the email changes
  };

  const validateEmailWithBackend = async () => {
    if (!email) {
      setEmailError('Email is required.');
      return;
    }

    setIsEmailVerifying(true);
    setEmailError('');
    try {
      const response = await fetch(
        'https://ariaslogin-4a95935f6093.herokuapp.com/api/verifyEmail',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        }
      );

      const result = await response.json();

      // Check strict validation criteria
      if (
        result.success && // API request was successful
        result.format_valid && // Email format is valid
        result.mx_found && // Domain has MX records
        !result.disposable // Not a disposable email
      ) {
        setEmailError('');
        setIsEmailVerified(true); // Email passes all criteria
      } else {
        let errorMessage = 'Email is invalid or not deliverable.';
        if (result.disposable) {
          errorMessage = 'Disposable email addresses are not allowed.';
        } else if (!result.mx_found) {
          errorMessage = 'Email domain does not have valid MX records.';
        } else if (!result.format_valid) {
          errorMessage = 'Email format is invalid.';
        }
        setEmailError(errorMessage);
        setIsEmailVerified(false);
      }
    } catch (error) {
      setEmailError('Error verifying email. Please try again.');
      setIsEmailVerified(false);
    } finally {
      setIsEmailVerifying(false);
    }
  };

  const handleSubmit = async () => {
    const fullPhone = phone.join('');
    if (fullPhone.length === 10 && !phoneError) {
        await handlePhoneBlur(); // Validate phone before submission
    }

    if (!emailError && !phoneError && isPhoneVerified && isEmailVerified) {
        // Reformat phone number to 1(xxx)xxx-xxxx
        const formattedPhone = `1(${fullPhone.slice(0, 3)})${fullPhone.slice(3, 6)}-${fullPhone.slice(6)}`;

        try {
            const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/create-userinfo', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    phone: formattedPhone,
                    userId: localStorage.getItem('userId'), // Assuming userId is stored in localStorage
                }),
            });

            const result = await response.json();

            if (result.success) {
                alert('Information saved successfully! You will now be logged out.');
                logoutUser(); // Log out the user
            } else {
                alert(`Error: ${result.message || 'Unable to save information.'}`);
            }
        } catch (error) {
            alert('An error occurred while saving your information. Please try again.');
        }
    } else {
        alert('Please fill in all fields correctly.');
    }
};

// Helper function to log out the user
const logoutUser = () => {
    // Clear local storage or any authentication tokens
    localStorage.clear(); 
    // Redirect to the login page
    window.location.href = '/login'; // Adjust the path to match your app's login route
};


  return (
    <div className="get-user-info-overlay">
      <div className="get-user-info-modal">
        <h2>Looks like we are missing some info for your account</h2>
        <p>This is nothing you did, but good news is it is an easy fix.</p>
        <div>
          <label className="get-user-info-label">Phone Number:</label>
          <div className="get-user-info-input-container">
            <div className="get-user-info-input-group">
              {phone.map((value, index) => (
                <input
                  key={index}
                  id={`phone-${index}`}
                  type="text"
                  maxLength={index === 2 ? 4 : 3}
                  value={value}
                  onChange={(e) => handlePhoneChange(index, e.target.value)}
                  onBlur={index === 2 ? handlePhoneBlur : null} // Only validate after full number is entered
                  onKeyDown={(e) =>
                    e.key === 'Backspace' &&
                    handlePhoneBackspace(index, e.target.value)
                  }
                  className="get-user-info-input"
                />
              ))}
            </div>
            {isPhoneVerified && (
              <div className="get-user-info-icon">
                <CheckmarkIcon />
              </div>
            )}
          </div>
          {phoneError && <p className="get-user-info-error">{phoneError}</p>}
        </div>

        <div>
          <label className="get-user-info-label">Email:</label>
          <div className="get-user-info-input-container">
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={validateEmailWithBackend} // Validate email only on blur
              className={`get-user-info-input ${isEmailVerified ? 'verified' : ''}`}
            />
            {isEmailVerified && (
              <div className="get-user-info-icon">
                <CheckmarkIcon />
              </div>
            )}
          </div>
          {isEmailVerifying && <p className="get-user-info-verifying">Verifying...</p>}
          {emailError && <p className="get-user-info-error">{emailError}</p>}
        </div>

        <button
    className="get-user-info-submit-button"
    onClick={handleSubmit}
    disabled={isEmailVerifying || !isPhoneVerified || !isEmailVerified}
>
    Submit
</button>

      </div>
    </div>
  );
};

export default GetUserInfo;
