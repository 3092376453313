import React, { useState, useEffect } from 'react';
import HistoryTable from './HistoryTable';
import Placeholder from '../utils/Placeholder.js';
function Presentation() {
    const [presentationHistory, setPresentationHistory] = useState([]);
    const [hierarchyData, setHierarchyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch hierarchy information
    useEffect(() => {
        const fetchHierarchyInfo = async () => {
            try {
                const userId = localStorage.getItem('userId'); // Get current user ID from localStorage
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/getHierarchyInfo', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ userId }),
                });
                const result = await response.json();

                if (result.success) {
                    setHierarchyData(result.data); // Store the full hierarchy info
                } else {
                    setError('Failed to fetch hierarchy information.');
                }
            } catch (err) {
                setError('Error fetching hierarchy information: ' + err.message);
            }
        };

        fetchHierarchyInfo();
    }, []);

    // Fetch presentation history
    useEffect(() => {
        const fetchPresentationHistory = async () => {
            try {
                const response = await fetch('https://ariaslogin-4a95935f6093.herokuapp.com/api/get-presentation-history');
                const result = await response.json();

                if (result.success) {
                    // Filter presentation data by IDs in hierarchy info
                    const hierarchyIds = hierarchyData.map((user) => user.id);
                    const filteredData = result.data.filter((row) => hierarchyIds.includes(row.user_id));

                    // Map lagnname into the presentation history data
                    const updatedData = filteredData.map((row) => {
                        const hierarchyMatch = hierarchyData.find((user) => user.id === row.user_id);
                        return {
                            ...row,
                            lagnname: hierarchyMatch ? hierarchyMatch.lagnname : 'Unknown',
                        };
                    });

                    setPresentationHistory(updatedData);
                } else {
                    setError('Failed to fetch presentation history.');
                }
            } catch (err) {
                setError('Error fetching presentation history: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        if (hierarchyData.length > 0) {
            fetchPresentationHistory();
        }
    }, [hierarchyData]);

    return (
        <div className='app-container'>
            <div className='large-app-container'>
            {loading ? (
                <Placeholder />
            ) : error ? (
                <p style={{ color: 'red' }}>{error}</p>
            ) : (
                <HistoryTable data={presentationHistory} />
            )}
        </div>
        </div>
    );
}

export default Presentation;
