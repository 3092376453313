import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "./MoreFilters.css";

const MoreFilters = ({
  onFilterChange,
  recruitingWeeks,
  recruitingMonths,
  recruitingYears,
  defaultMGA,
  defaultTree,
  allMGAs,
  allRGAs,
  allLegacies,
  allTrees,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    periodType: "week",
    periodValue: [],
    MGA: "",
    RGA: "",
    Legacy: "",
    Tree: "",
  });

  const popupRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(false); // Open by default

  useEffect(() => {
    const defaultMonth = recruitingMonths?.[0]?.value || "";
    const defaultMGAValue = defaultMGA || "";
    const defaultTreeValue = defaultTree || "";
  
    setFilters({
      periodType: "month", // Default to month
      periodValue: [defaultMonth], // Use the most recent month
      MGA: defaultMGAValue,
      RGA: "",
      Legacy: "",
      Tree: defaultTreeValue,
    });
  
    // Notify parent component about the default filters
    onFilterChange({
      periodType: "month",
      periodValue: [defaultMonth],
      MGA: defaultMGAValue,
      RGA: "",
      Legacy: "",
      Tree: defaultTreeValue,
    });
  }, [recruitingMonths, defaultMGA, defaultTree]);
  
  
  

  const handleFilterChange = (name, value) => {
    let updatedFilters = {
      ...filters,
      [name]: name === "periodValue" && !Array.isArray(value) ? [value] : value,
    };
  
    if (name === "periodType") {
      const newPeriodOptions = getPeriodOptions(value);
      const defaultPeriodValue = newPeriodOptions[0]?.value || "";
      updatedFilters = { ...updatedFilters, periodValue: [defaultPeriodValue] };
    }

    
    console.log('filters', updatedFilters);
    setFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };
  

  const handlePeriodChange = (direction) => {
    const options = getPeriodOptions();
    const currentIndexes = filters.periodValue.map((value) =>
      options.findIndex((option) => option.value === value)
    );
  
    const newIndexes = currentIndexes.map((index) => index - direction);
    const validIndexes = newIndexes.filter(
      (index) => index >= 0 && index < options.length
    );
  
    const newValues = validIndexes.map((index) => options[index].value);
    handleFilterChange("periodValue", newValues);
  };
  

  const getPeriodOptions = (type = filters.periodType) => {
    switch (type) {
      case "month":
        return recruitingMonths;
      case "year":
        return recruitingYears;
      default:
        return recruitingWeeks;
    }
  };

  const handleOutsideClick = (e) => {
    if (popupRef.current && !popupRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

return (
  <div className="morefilters-container">
    <hr />
    <div className="more-header-with-toggle-and-filters">
    <h5
  className={`morefilters-collapse-header ${isCollapsed ? "collapsed" : ""}`}
  onClick={() => setIsCollapsed((prev) => !prev)}
>
  Filters <span className="arrow">{isCollapsed ? ">" : "v"}</span>
</h5>
</div>
    {!isCollapsed && (
      <div className="morefilters-section">
        <div className="morefilters-filter-group-row">
        <div className="morefilters-filter-group">
          <label>Filter By:</label>
          <div className="morefilters-period-type-buttons">
            {["week", "month", "year"].map((type) => (
              <button
                key={type}
                className={`morefilters-period-button ${
                  filters.periodType === type ? "active" : ""
                }`}
                onClick={() => handleFilterChange("periodType", type)}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>
        </div>
        <div className="morefilters-filter-group-big">
          <label htmlFor="periodValue">Select {filters.periodType}:</label>
          <div className="dropdown-with-navigation">
            <button
              className="more-week-button"
              onClick={() => handlePeriodChange(-1)}
              disabled={
                getPeriodOptions().findIndex(
                  (option) => option.value === filters.periodValue
                ) === getPeriodOptions().length - 1
              }
            >
              &lt;
            </button>
            <Select
            className="morefilters-period-value-select"
              id="periodValue"
              name="periodValue"
              value={filters.periodValue.map((value) => {
                if (filters.periodType === "week") {
                  return { value, label: new Date(value).toLocaleDateString() };
                }
                if (filters.periodType === "month") {
                  const [year, month] = value.split("-");
                  const date = new Date(year, month - 1);
                  return {
                    value,
                    label: date.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    }),
                  };
                }
                if (filters.periodType === "year") {
                  return { value, label: value };
                }
                return { value, label: value };
              })}
              onChange={(selectedOptions) =>
                handleFilterChange(
                  "periodValue",
                  selectedOptions.map((option) => option.value)
                )
              }
              options={getPeriodOptions().map((option) => {
                if (filters.periodType === "month") {
                  const [year, month] = option.value.split("-");
                  const date = new Date(year, month - 1);
                  return {
                    value: option.value,
                    label: date.toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    }),
                  };
                }
                if (filters.periodType === "year") {
                  return { value: option.value, label: option.label };
                }
                return { value: option.value, label: option.label };
              })}
              isMulti
            />
            <button
              className="more-week-button"
              onClick={() => handlePeriodChange(1)}
              disabled={
                getPeriodOptions().findIndex(
                  (option) => option.value === filters.periodValue
                ) === 0
              }
            >
              &gt;
            </button>
          </div>
        </div>
        </div>
        <div className="morefilters-filter-group-row">
        <div className="morefilters-filter-group">
          <label htmlFor="MGA">MGA:</label>
          <Select
            id="MGA"
            name="MGA"
            value={filters.MGA ? { value: filters.MGA, label: filters.MGA } : null}
            onChange={(selectedOption) =>
              handleFilterChange("MGA", selectedOption?.value || "")
            }
            options={allMGAs.map((mga) => ({ value: mga, label: mga }))}
            isClearable
          />
        </div>
        <div className="morefilters-filter-group">
          <label htmlFor="RGA">RGA:</label>
          <Select
            id="RGA"
            name="RGA"
            value={filters.RGA ? { value: filters.RGA, label: filters.RGA } : null}
            onChange={(selectedOption) =>
              handleFilterChange("RGA", selectedOption?.value || "")
            }
            options={allRGAs.map((rga) => ({ value: rga, label: rga }))}
            isClearable
          />
        </div>
        <div className="morefilters-filter-group">
          <label htmlFor="Tree">Tree:</label>
          <Select
            id="Tree"
            name="Tree"
            value={filters.Tree ? { value: filters.Tree, label: filters.Tree } : null}
            onChange={(selectedOption) =>
              handleFilterChange("Tree", selectedOption?.value || "")
            }
            options={allTrees.map((tree) => ({ value: tree, label: tree }))}
            isClearable
          />
        </div>
        </div>
      </div>
    )}
  </div>
);

};

export default MoreFilters;
