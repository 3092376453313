import React, { useState, useEffect } from 'react';
import PresentationDetails from './PresentationDetails';

function HistoryTable({ data }) {
    const [selectedPresentation, setSelectedPresentation] = useState(null);
    const [sortedData, setSortedData] = useState([]);

    useEffect(() => {
        // Sort data by time_started in descending order (newest to oldest)
        const sorted = [...data].sort((a, b) => new Date(b.time_started) - new Date(a.time_started));
        setSortedData(sorted);
    }, [data]);
    

    const handleRowClick = (presentation) => {
        setSelectedPresentation(presentation);
    };

    const handleBack = () => {
        setSelectedPresentation(null);
    };

    return (
        <div style={{ overflowX: 'auto' }}>
            <h2>Presentation History</h2>
            {selectedPresentation ? (
                <PresentationDetails
                    presentation={selectedPresentation}
                    onBack={handleBack}
                />
            ) : (
                <table className="recruiting-table">
                    <thead>
                        <tr>
                            <th>Account</th>
                            <th>Ran By</th>
                            <th>Primary Name</th>
                            <th>Time Started</th>
                            <th>Time Ended</th>
                            <th>Lead Type</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData.length === 0 ? (
                            <tr>
                                <td colSpan="8">No presentation history found.</td>
                            </tr>
                        ) : (
                            sortedData.map((row, index) => (
                                <tr
                                    key={index}
                                    onClick={() => handleRowClick(row)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td>{row.lagnname}</td>
                                    <td>{row.agent_name}</td>
                                    <td>{row.primary_name}</td>
                                    <td>{new Date(row.time_started).toLocaleString()}</td>
                                    <td>
                                        {row.time_ended
                                            ? new Date(row.time_ended).toLocaleString()
                                            : 'N/A'}
                                    </td>
                                    <td>{row.lead_type}</td>
                                    <td>{row.city}</td>
                                    <td>{row.state}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default HistoryTable;
